<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-end py-1">
        <b-col md="8" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">
            {{ subject.name }} with {{ subject.teacher_data.name }}.
          </h3>
          <p class="font-weight-bold text-colorGray mb-50">{{ subject.code }}</p>
        </b-col>
        <b-col md="4" class="px-3">
          <div
            class="pb-1 pt-50 px-2"
            style="border-radius: 20px; border: 2px solid lightgray"
          >
            <b-row class="d-flex align-items-center justify-content-end">
              <b-col md="9">
                <h6 class="font-weight-bolder text-colorBlack">
                  {{lectures_completed}}/{{ totalItems }} Lectures Completed
                </h6>
              </b-col>
              <b-col md="3"
                ><h2 class="font-weight-bolder text-colorBlack">{{ lectures_completed*100/totalItems }}%</h2>
              </b-col>
            </b-row>
            <b-progress
              class="mt-2"
              :value="lectures_completed"
              :max="totalItems"
              show-progress
              animated
            ></b-progress>
          </div>
        </b-col>
      </b-row>
      <b-row class="px-2 w-100">
        <b-col md="12">
          <b-tabs content-class="mt-50">
            <b-tab class="pl-1" active>
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack">Lectures</span>
              </template>
              <b-row class="d-flex align-items-center justify-content-start">
                <b-col md="8" class="py-50">
                  <h3 class="font-weight-bolder text-colorBlack m-0">
                    Lectures
                  </h3>
                </b-col>
                <!-- <b-col md="4">
                  <div class="text-right">
                    <b-link :to="{ name: 'LectureDescription' }">
                      <img
                        src="@/assets/images/icons/lecture/vector.svg"
                        img-fluid
                        width="40px"
                        height="40px"
                      />
                    </b-link>
                    <b-link :to="{ name: 'OxarDescription' }">
                      <img
                        src="@/assets/images/icons/lecture/vector-2.svg"
                        img-fluid
                        class="ml-1"
                        width="40px"
                        height="40px"
                      />
                    </b-link>
                  </div>
                </b-col> -->
              </b-row>

              <b-card class="mt-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsLectures"
                  :items="lectures"
                  details-td-class="m-0 p-0"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(Lecture)="row">
                    <div class="py-3">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.Lecture ? row.item.Lecture : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(LectureView)="row">
                    <feather-icon icon="EyeIcon" />
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.LectureView ? row.item.LectureView : "" }}
                    </span>
                  </template>
                  <template #cell(DateTime)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.DateTime ? row.item.DateTime : "" }}
                    </span>
                  </template>
                  <template #cell(Status)="row">
                    <b-row
                      class="d-flex align-items-center justify-content-end"
                    >
                      <b-col md="12">
                        <h6
                          class="font-weight-bolder text-colorBlack m-0"
                          style="font-size: 0.8rem"
                        >
                          {{ row.item.Status ? row.item.Status : "" }}
                        </h6>
                      </b-col>
                    </b-row>
                    <b-progress
                      class="mt-50"
                      :value="lectures_completed"
                      :max="totalItems"
                      show-progress
                      animated
                    ></b-progress>
                  </template>
                  <template #cell(manage)="row">
                    <b-button
                      variant="colorBlue"
                      rounded
                      size="sm"
                      class="text-center ml-0 ml-md-4"
                      @click="startLecture(row.item.id)"
                    >
                      Start Lecture
                    </b-button>
                  </template>
                </b-table>
              </b-card>
            </b-tab>
            <!-- <b-tab class="pl-1">
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack"
                  >Study Material
                </span>
              </template>
              <b-row class="d-flex align-items-center justify-content-start">
                <b-col md="8" class="py-50">
                  <h3 class="font-weight-bolder text-colorBlack m-0">
                    Study Material
                  </h3>
                </b-col>
                <b-col md="4">
                  <div class="text-right">
                    <b-link :to="{ name: 'LectureDescription' }">
                      <img
                        src="@/assets/images/icons/lecture/vector.svg"
                        img-fluid
                        width="40px"
                        height="40px"
                      />
                    </b-link>
                    <b-link :to="{ name: 'OxarDescription' }">
                      <img
                        src="@/assets/images/icons/lecture/vector-2.svg"
                        img-fluid
                        class="ml-1"
                        width="40px"
                        height="40px"
                      />
                    </b-link>
                  </div>
                </b-col>
              </b-row>
              <b-card class="mt-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsStudyMaterial"
                  :items="StudyMaterial"
                  details-td-class="m-0 p-0"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(Lecture)="row">
                    <div class="py-3">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        <img
                          src="@/assets/images/icons/assignment/pdf.svg"
                          img-fluid
                          width="40px"
                          height="40px"
                          class="mr-50"
                        />
                        {{ row.item.Lecture ? row.item.Lecture : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(DateTime)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.DateTime ? row.item.DateTime : "" }}
                    </span>
                  </template>

                  <template #cell(manage)="">
                    <b-button
                      variant="colorBlue"
                      rounded
                      size="sm"
                      class="text-center ml-0 ml-md-4"
                    >
                      <feather-icon icon="DownloadIcon" class="mr-50" />
                      Download
                    </b-button>
                  </template>
                </b-table>
              </b-card>
            </b-tab> -->
            
          </b-tabs></b-col
        >
      </b-row>
    </div>
    <ViewFeedbackModal
      :modalVisible="modalVisibleFeedback"
      @close="closeModalFeedback"
    />
    <AssignmentDetailModal :modalVisible="modalVisible" @close="closeModal" />
    <AssignmentUploadModal
      :modalVisible="modalVisibleUpload"
      @close="closeModalUpload"
    />
  </div>
</template>

<script>
import AssignmentUploadModal from "@/components/student/assignment/AssignmentUploadModal.vue";
import ViewFeedbackModal from "@/components/student/assignment/ViewFeedbackModal.vue";
import AssignmentDetailModal from "@/components/student/assignment/AssignmentDetailModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ViewFeedbackModal,
    AssignmentDetailModal,
    AssignmentUploadModal,
  },
  props: ['id'],
  data() {
    return {
      fieldsLectures: [
        { key: "title", label: "Lectures" },
        { key: "created_at", label: "Date & Time" },
        { key: "viewCount", label: "Lecture View" },
        { key: "Status", label: "Status" },
        { key: "manage", label: "" },
      ],
      Lectures: [
        {
          Lecture: " Introduction to Data Science & ML",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
          LectureView: "4",
          Status: "In Progress 20%",
        },
        
      ],
      fieldsStudyMaterial: [
        { key: "Lecture", label: "Lecture 1" },
        { key: "DateTime", label: "Date & Time" },
        { key: "manage", label: "" },
      ],
      StudyMaterial: [
        {
          Lecture: " Data Parsing.mp4",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
        },
        {
          Lecture: " Intro to Data Parsing.pdf",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
        },
        {
          Lecture: " Intro to Data Parsing.pdf",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
        },
        {
          Lecture: " Intro to Data Parsing.pdf",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
        },
      ],
      fieldsAssignment: [
        { key: "Assignmnet", label: "Assignmnet" },
        { key: "Deadline", label: "Deadline" },
        { key: "Grade", label: "Grade " },
        { key: "Status", label: "Status" },
        { key: "manage", label: "" },
      ],
      Assignment: [
        {
          Assignmnet: " Introduction to Data Science & ML",
          Deadline: "24 Sep, 2020 02:00 pm",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
          Grade: "A",
          Status: "New",
        },
        {
          Assignmnet: " Introduction to Data Science & ML",
          Deadline: "24 Sep, 2020 02:00 pm ",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
          Grade: "-",
          Status: "New",
        },
        {
          Assignmnet: " Introduction to Data Science & ML",
          Deadline: "24 Sep, 2020 02:00 pm",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
          Grade: "A",
          Status: "New",
        },
        {
          Assignmnet: " Introduction to Data Science & ML",
          Deadline: "24 Sep, 2020 02:00 pm ",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
          Grade: "-",
          Status: "New",
        },
      ],
      subjectCode: "",
      value: 0,
      max: 100,
      modalVisible: false,
      modalVisibleFeedback: false,
      modalVisibleUpload: false,
      lectures : [],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      subject: {},
      lectures_completed: 0,
    };
  },
  methods: {
    ...mapActions({
      getLectures: "appData/getLectures",
      getSubject: "appData/getSubject",
    }),
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    openModalFeedback() {
      this.modalVisibleFeedback = true;
    },
    closeModalFeedback() {
      this.modalVisibleFeedback = false;
    },
    openModalUpload() {
      this.modalVisibleUpload = true;
    },
    closeModalUpload() {
      this.modalVisibleUpload = false;
    },

    async fetchPaginatedData() {
      try {
        const res_subject = await this.getSubject({pk: this.$route.query.id});
        this.subject = res_subject.data;

        const res = await this.getLectures({
          pageNumber: this.currentPage,
          subject: this.subject.id,
        });
        this.lectures = res.data.results;  
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },

    startLecture(id){
      const baseUrl = 'http://ec2-13-49-57-0.eu-north-1.compute.amazonaws.com/lecture'; // The base URL for the lecture
      const fullUrl = `${baseUrl}/${id}`; // Append the id to the base URL

      window.open(fullUrl, '_blank'); // Open the link in a new tab
    }

  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },

    "$route.query.id"(newId, oldId) {
      if (newId !== oldId) {
        this.fetchPaginatedData();
      }
    },
  },
  async mounted() {
    await this.fetchPaginatedData();
  },
};
</script>

<style>
.tableHeadColor,
.table thead th,
thead,
th {
  background-color: #ebf0fc !important;
}
</style>
